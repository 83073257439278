.contact-container {
    padding: 60px 20px;
    background-color: #f4f4f7;
    text-align: center;
    font-family: 'Arial', sans-serif;
  }
  
  .contact-info {
    margin-bottom: 40px;
  }
  
  .contact-info h2 {
    font-size: 2.8rem;
    color: #333;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .contact-details {
    display: flex;
    justify-content: center;
    gap: 40px;
    margin-top: 30px;
    flex-wrap: wrap;
  }
  
  .contact-item {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 260px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .contact-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  }
  
  .contact-item h3 {
    font-size: 1.6rem;
    color: #0056b3;
    margin-top: 10px;
  }
  
  .contact-item p {
    font-size: 1.1rem;
    margin-top: 5px;
  }
  
  .contact-item a {
    color: #333;
    text-decoration: none;
    font-weight: 500;
  }
  
  .contact-item a:hover {
    color: #007bff;
    text-decoration: underline;
  }
  
  .contact-icon {
    font-size: 3rem;
    color: #0056b3;
    margin-bottom: 15px;
  }
  
  .quote-request {
    margin-top: 40px;
  }
  
  .quote-btn {
    background-color: #0056b3;
    color: white;
    font-size: 1.3rem;
    padding: 15px 40px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .quote-btn:hover {
    background-color:  #007bff;
    transform: scale(1.05);
  }
  /* Back-to-Top Button */
.back-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  background-color: #007bff; /* Blue color */
  color: white;
  border: none;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1000;
  transition: all 0.3s ease;
}

.back-to-top:hover {
  background-color: #0056b3; /* Darker blue on hover */
  transform: scale(1.1); /* Slightly enlarge on hover */
}

/* Hide Back-to-Top Button by Default */
.back-to-top {
  opacity: 0;
  visibility: hidden;
}

/* Show Back-to-Top Button when visible */
.back-to-top {
  opacity: 1;
  visibility: visible;
}
  