/* General Styling */
.about-header {
    
    color: #333;
    padding: 20px 10px;
    text-align: center;
    
  }
  
  .about-header h1 {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  .about-header p {
    font-size: 1.2rem;
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto;
  }
  
  /* Section Styling */
  .about-section {
    padding: 60px 20px;
    
  }
  
  .about-section h2 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .about-section p {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
    margin: 20px 0;
  }
  
  /* Grid Layouts */
  .grid-layout {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
  }
  
  .grid-layout.reverse-grid {
    flex-direction: row-reverse;
    border-radius: 10%;
    border-color: #000000;
  }
  
  .text-content {
    flex: 1;
    text-align: left;
    
  }
  
  .image-content {
    flex: 1;
  }
  
  .responsive-img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  /* Values Section */
  .values-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 40px;
  }
  
  .value-item {
    background-color: #fcfcfc;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .value-item h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .value-item p {
    font-size: 1rem;
    color: #666;
  }

.tick-icon {
    color: #28a745; 
    margin-right: 10px; 
    font-size: 1.2rem; 
  }
.no-bullets{
    list-style-type: none; 
}
/* Back-to-Top Button */
.back-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  background-color: #007bff; /* Blue color */
  color: white;
  border: none;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1000;
  transition: all 0.3s ease;
}

.back-to-top:hover {
  background-color: #0056b3; /* Darker blue on hover */
  transform: scale(1.1); /* Slightly enlarge on hover */
}

/* Hide Back-to-Top Button by Default */
.back-to-top {
  opacity: 0;
  visibility: hidden;
}

/* Show Back-to-Top Button when visible */
.back-to-top {
  opacity: 1;
  visibility: visible;
}
.about-section vision-section{

}